import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import StoveItems from '../components/StoveItems';

export default function StovesListPage({ data, pageContext }) {
  const stoves = data.stoves.nodes;
  const breadcrumbs = [
    {
      title: 'Painting',
      url: '/painting',
    },
  ];
  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <StoveItems
        stoves={stoves}
        menuUrl="painting"
        parentUrl={pageContext.url}
      />
    </Layout>
  );
}

export const query = graphql`
  query PaintingStovesListQuery($url: String) {
    stoves: allSanityStove(
      filter: { family: { url: { current: { eq: $url } } } }
    ) {
      nodes {
        id
        name
        url {
          current
        }
        family {
          url {
            current
          }
        }
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
